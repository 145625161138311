@import "./shared/index.scss";

.modal-wrap {
  display: none;

  &.modal-open {
    display: block;

    .modal-bg {
      content: "";
      height: 100vh;
      width: 100vw;
      position: fixed;
      background: $overlay;
      top: 0;
      left: 0;
      z-index: 4;
    }

    .modal {
      .checkbox-container {
        margin-bottom: 0;
      }
      .form-field--checkbox-wrap,
      .filter-people,
      .filter-type {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
      }
      .form-field--checkbox {
        width: 20%;
        padding: 8px 0;

        &.form-field--checkbox-full {
          // styles for the full width checkboxes in the edit event form
          width: 100%;

          .checkbox {
            top: 3px;
          }

          .checkbox-container {
            display: flex;
            align-items: flex-start !important;
          }
        }
      }

      background: #fff;
      padding: 40px;
      position: fixed;
      z-index: 100;
      max-height: 80vh;
      width: 80vw;
      top: 10vh;
      left: 10vw;
      box-shadow: 0px 0px 62px -13px rgba(173, 173, 173, 1);
      overflow-y: scroll;

      @media (max-width: 1250px) {
        .form-field--checkbox {
          width: 25%;
        }
      }

      @media (max-width: $break-mobile) {
        max-height: 100vh;
        height: 100vh;
        width: 100%;
        top: 0;
        left: 0;
        display: block;
        padding-bottom: 120px; // since chrome menu bar sometimes covers it up
        .form-field--checkbox {
          width: 100%;
        }
      }
    }

    .modal-close {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 3;

      @media (max-width: $break-mobile) {
        top: 24px;
        position: fixed;
      }

      &:before {
        content: "";
        background: #fff;
        width: 46px;
        height: 46px;
        border-radius: 49px;
        top: -10px;
        left: -12px;
        opacity: 1;
        position: absolute;
        transition: 0.2s all;
        z-index: -1;
      }
    }

    .filter-title {
      margin-top: 0;
      margin-bottom: 5px;
    }

    .filter-header {
      * {
        display: inline-block;
      }
      .check-all {
        color: $action-color-dark;
        padding: 20px;
        margin: 0 0;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
    }

    .filter-block--type {
      height: 20%;
    }

    .filter-block--people {
      height: 80%;

      .filter-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        @media (max-width: $break-mobile) {
          padding-top: 15px;
          align-items: flex-start;
          flex-direction: column;
        }
      }

      .exclusive-wrapper {
        display: flex;
        align-items: flex-end;
        margin-bottom: 23px;
      }

      .exclusive-label {
        font-size: 13px;
        margin: 0 16px 0 0;
      }
    }

    @media (max-width: $break-mobile) {
      .filter-block--type,
      .filter-block--people {
        height: auto;
      }
    }

    .filter-wrap {
      height: calc(100% - 72px);
      width: 100%;
      top: 0;
      left: 0;
    }

    .filter {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: calc(100% - 32px);

      &.filter-type {
        .checkbox-container {
          .checkmark:after {
            border-color: $dark-text;
          }

          &.checkbox--event {
            input:checked ~ .checkmark {
              background-color: $event;
            }
          }
        }
        .checkbox-container {
          &.checkbox--intro {
            input:checked ~ .checkmark {
              background-color: $intro;
            }
          }
        }
        .checkbox-container {
          &.checkbox--move {
            input:checked ~ .checkmark {
              background-color: $move;
            }
          }
        }
        .checkbox-container {
          &.checkbox--trip {
            input:checked ~ .checkmark {
              background-color: $trip;
            }
          }
        }
      }
    }
  }
}
