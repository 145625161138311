@import "../../styles/shared/index.scss";

.loader-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
}

//thx to https://codepen.io/chris22smith/pen/gOpdmma
.loader {
  animation: spin 1s infinite linear;
  border: solid 2vmin transparent;
  border-radius: 50%;
  border-right-color: $gradient-1;
  border-top-color: $gradient-1;
  box-sizing: border-box;
  height: 20vmin;
  left: calc(50% - 10vmin);
  top: calc(50% - 10vmin);
  width: 20vmin;
  z-index: 1;
  &:before {
    animation: spin 2s infinite linear;
    border: solid 2vmin transparent;
    border-radius: 50%;
    border-right-color: $gradient-2;
    border-top-color: $gradient-2;
    box-sizing: border-box;
    content: "";
    height: 16vmin;
    left: 0;
    position: absolute;
    top: 0;
    width: 16vmin;
  }
  &:after {
    animation: spin 3s infinite linear;
    border: solid 2vmin transparent;
    border-radius: 50%;
    border-right-color: $gradient-3;
    border-top-color: $gradient-3;
    box-sizing: border-box;
    content: "";
    height: 12vmin;
    left: 2vmin;
    position: absolute;
    top: 2vmin;
    width: 12vmin;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
