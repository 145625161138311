@import "./shared/index.scss";

//// FORM
form {
  .form-title {
    margin-top: 0;
  }

  .form-field {
    padding: 8px 0;
  }

  input,
  textarea {
    border: 1px solid $form-field-border;
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 14px;
    transition: 0.3s border-color;
    margin-bottom: 0;
    &:focus {
      outline: none;
      border-color: $form-field-border-focus;
    }
  }

  .form-label {
    display: block;
    font-weight: bold;
    padding-bottom: 4px;
    margin: 0 0;
  }

  .form-help {
    margin: 0 0;
    font-size: 13px;
  }

  input[type="submit"] {
    border: none;
    background: $action-color-dark;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    display: block;
    width: auto;
    padding: 12px 50px;
    margin: 20px auto 0;
    transition: 0.3s opacity;
    -webkit-appearance: none;
    &:hover {
      opacity: 0.9;
    }
  }

  .submit-label {
    @media (max-width: $break-mobile) {
      display: block;
    }
  }

  .submit-data {
    margin-left: 8px;
    background: $action-color-light;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    display: inline-block;

    @media (max-width: $break-mobile) {
      margin-left: 0;
      margin-top: 5px;
    }
  }

  .form-field--checkbox label {
    display: flex;
    align-items: center;

    @media (max-width: $break-mobile) {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  .form-flex {
    display: flex;
    .form-field {
      flex-grow: 1;
      &:not(:first-child) {
        padding-left: 20px;
      }
    }
    @media (max-width: $break-mobile) {
      flex-direction: column;
      .form-field {
        &:not(:first-child) {
          padding-left: 0;
        }
      }
    }
  }

  .form-field--checkbox-date {
    padding-left: 20px !important;
    padding-bottom: 22px !important;
    @media (max-width: $break-mobile) {
      padding-left: initial !important;
      padding-bottom: initial !important;
    }
  }

  .form-flex-date {
    align-items: flex-end;
    @media (max-width: $break-mobile) {
      align-items: flex-start;
      .form-field {
        width: 100%;
      }
    }
  }
}

/* Customize the label (the container) */
.radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default radio button */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: $checkmark;
    border-radius: 50%;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: $checkmark-hover;
  }

  /* When the radio button is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: $action-color;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .checkmark:after {
    top: 6px;
    left: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #fff;
  }
}

/* Customize the label (the checkbox-container) */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  padding-right: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media (max-width: $break-mobile) {
    width: 100%;
  }

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: $checkmark;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: $checkmark-hover;
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: $action-color;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 26px;

  /* Hide default HTML checkbox */
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $slider-color;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 3px;
    bottom: 3px;
    background-color: #fff;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: $action-color;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $action-color;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.modal--edit {
  .checkbox-container .checkmark {
    top: 3px;
    @media (max-width: $break-mobile) {
      top: 0;
    }
  }
}
